// src/components/EntryManagementPage.js
import React, { useState, useEffect, useRef } from 'react'; // useState, useEffect und useRef importiert
import axios from 'axios';
import qs from 'qs'; // Neu hinzugefügt
import DynamicCheckboxes from './DynamicCheckboxes';
import EditEntryForm from './EditEntryForm'; // Import des EditEntryForm-Komponents
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFilter, faTrash, faEdit, faDownload, faExpand, faArrowLeft, faArrowRight } from '@fortawesome/free-solid-svg-icons'; // Icons importieren
import './EntryManagementPage.css';
import './DynamicCheckboxes.css'; // Importiere die CSS-Datei von DynamicCheckboxes
import { Snackbar, Alert, IconButton, Tooltip, Slider } from '@mui/material'; // Neu hinzugefügt
import { Remove, Add } from '@mui/icons-material'; // Icons für Textgrößenanpassung
import Cookies from 'js-cookie'; // Neu hinzugefügt für Cookie-Verwaltung

function EntryManagementPage() {
    const [entries, setEntries] = useState([]);
    const [nameFilter, setNameFilter] = useState('');
    const [kategorien, setKategorien] = useState([]);
    const [filterKategorien, setFilterKategorien] = useState([]);
    const [page, setPage] = useState(1);
    const [limit, setLimit] = useState(10);
    const [totalPages, setTotalPages] = useState(1);
    const [showFilterModal, setShowFilterModal] = useState(false);
    const [filterModalKategorien, setFilterModalKategorien] = useState([]);
    const [isEditModalOpen, setIsEditModalOpen] = useState(false);
    const [entryToEdit, setEntryToEdit] = useState(null);
    const [openSnackbar, setOpenSnackbar] = useState(false); // Snackbar-Status
    const [snackbarMessage, setSnackbarMessage] = useState('');
    const [snackbarSeverity, setSnackbarSeverity] = useState('success');
    const [textSize, setTextSize] = useState(16); // Standard-Textgröße
    const [sortOrder, setSortOrder] = useState('asc'); // Zustand für die Sortierreihenfolge

    // Initial state mit Fallback auf Standardwerte
    const [modalSize, setModalSize] = useState(() => {
        const savedSize = Cookies.get('modalSize');
        if (savedSize) {
            try {
                const parsedSize = JSON.parse(savedSize);
                if (parsedSize.width && parsedSize.height) {
                    return parsedSize;
                }
            } catch (error) {
                console.error('Fehler beim Parsen der gespeicherten Modal-Größe:', error);
            }
        }
        return { width: 800, height: 600 }; // Standardwerte als Fallback
    });
    const [isResizing, setIsResizing] = useState(false);

    const modalContentRef = useRef(null); // Referenz für das Modal-Inhaltselement

    // Neuer State für Filter-Modal-Größe
    const [filterModalSize, setFilterModalSize] = useState(() => {
        const savedSize = Cookies.get('filterModalSize');
        if (savedSize) {
            try {
                const parsedSize = JSON.parse(savedSize);
                if (parsedSize.width && parsedSize.height) {
                    return parsedSize;
                }
            } catch (error) {
                console.error('Fehler beim Parsen der gespeicherten Filter-Modal-Größe:', error);
            }
        }
        return { width: 600, height: 400 }; // Standardwerte für Filter-Modal
    });

    const filterModalRef = useRef(null);
    const [isFilterResizing, setIsFilterResizing] = useState(false);

    // Lade die Textgröße beim Komponenten-Mount aus den Cookies
    useEffect(() => {
        const savedTextSize = Cookies.get('textSize');
        if (savedTextSize) {
            setTextSize(parseInt(savedTextSize));
        }
    }, []);

    // Lade die gespeicherte Modal-Größe aus den Cookies beim Komponenten-Mount
    useEffect(() => {
        const savedModalSize = Cookies.get('modalSize');
        if (savedModalSize) {
            try {
                const parsedSize = JSON.parse(savedModalSize);
                if (parsedSize.width && parsedSize.height) {
                    setModalSize(parsedSize);
                }
            } catch (error) {
                console.error('Fehler beim Parsen der gespeicherten Modal-Größe:', error);
            }
        }
    }, []);

    useEffect(() => {
        fetchKategorien();
    }, []);

    useEffect(() => {
        fetchEntries();
    }, [page, limit, filterKategorien, nameFilter, textSize]);

    const fetchKategorien = () => {
        axios.get('https://api.divingmaster.net/api/categories')
            .then(response => {
                setKategorien(response.data);
            })
            .catch(error => {
                console.error('Fehler beim Abrufen der Kategorien:', error);
            });
    };

    const fetchEntries = () => {
        const filters = {
            page,
            limit,
        };

        if (nameFilter) {
            filters.name = nameFilter;
        }

        if (filterKategorien.length > 0) {
            // Serialisiere filterKategorien zu einem JSON-String
            filters.kategorien = JSON.stringify(filterKategorien);
        }

        axios.get('https://api.divingmaster.net/api/entries', {
            params: filters,
            paramsSerializer: params => {
                return qs.stringify(params); // Keine speziellen Optionen nötig
            }
        })
            .then(response => {
                const entriesWithExpanded = response.data.entries.map(entry => ({
                    ...entry,
                    isExpanded: false,
                }));
                setEntries(entriesWithExpanded);
                setTotalPages(response.data.totalPages);
            })
            .catch(error => {
                console.error('Fehler beim Abrufen der Einträge:', error);
            });
    };

    const handleFilterModalMouseMove = (e) => {
        if (!isFilterResizing) return;
        if (!filterModalRef.current) return;

        const rect = filterModalRef.current.getBoundingClientRect();
        setFilterModalSize({
            width: e.clientX - rect.left,
            height: e.clientY - rect.top
        });
    };

    const startFilterResize = () => setIsFilterResizing(true);
    const stopFilterResize = () => {
        setIsFilterResizing(false);
        // Speichere die aktuelle Größe in den Cookies
        Cookies.set('filterModalSize', JSON.stringify(filterModalSize), { expires: 365 });
    };

    const handleOpenFilterModal = () => {
        // Lade die gespeicherte Größe, bevor das Modal geöffnet wird
        const savedSize = Cookies.get('filterModalSize');
        if (savedSize) {
            try {
                const parsedSize = JSON.parse(savedSize);
                if (parsedSize.width && parsedSize.height) {
                    setFilterModalSize(parsedSize);
                }
            } catch (error) {
                console.error('Fehler beim Laden der Filter-Modal-Größe:', error);
            }
        }

        const kategorienCopy = JSON.parse(JSON.stringify(kategorien));
        setFilterModalKategorien(kategorienCopy);
        setShowFilterModal(true);
    };

    const handleCloseFilterModal = () => {
        // Speichere die aktuelle Modal-Größe in den Cookies
        const modalContent = filterModalRef.current;
        if (modalContent) {
            const size = {
                width: modalContent.offsetWidth,
                height: modalContent.offsetHeight
            };
            Cookies.set('filterModalSize', JSON.stringify(size), { expires: 365 });
        }
        setShowFilterModal(false);
    };

    const handleApplyFilters = () => {
        const selectedOptions = [];

        filterModalKategorien.forEach(category => {
            category.unterkategorien.forEach(sub => {
                sub.optionen.forEach(option => {
                    if (option.isChecked) {
                        selectedOptions.push({
                            category: category.name,
                            subcategory: sub.name,
                            option: option.name
                        });
                    }
                });
            });
        });

        setFilterKategorien(selectedOptions);
        setShowFilterModal(false);
    };

    const handleFilterKategorienChange = (updatedKategorien) => {
        setFilterModalKategorien(updatedKategorien);
    };

    const handleDeleteEntry = (id) => {
        if (window.confirm('Möchten Sie diesen Eintrag wirklich löschen?')) {
            axios
                .delete(`https://api.divingmaster.net/api/entries/${id}`)
                .then(() => {
                    setSnackbarMessage('Eintrag erfolgreich gelöscht.');
                    setSnackbarSeverity('success');
                    setOpenSnackbar(true);
                    fetchEntries();
                })
                .catch((error) => {
                    console.error('Fehler beim Löschen des Eintrags:', error);
                    setSnackbarMessage('Fehler beim Löschen des Eintrags.');
                    setSnackbarSeverity('error');
                    setOpenSnackbar(true);
                });
        }
    };

    // Handler zum Schließen der Snackbar
    const handleCloseSnackbar = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        setOpenSnackbar(false);
    };

    const handleEditEntry = (entry) => {
        // Lade die gespeicherte Größe, bevor das Modal geöffnet wird
        const savedSize = Cookies.get('modalSize');
        if (savedSize) {
            try {
                const parsedSize = JSON.parse(savedSize);
                if (parsedSize.width && parsedSize.height) {
                    setModalSize(parsedSize);
                }
            } catch (error) {
                console.error('Fehler beim Laden der Modal-Größe:', error);
            }
        }
        
        setEntryToEdit(entry);
        setIsEditModalOpen(true);
    };

    const handleCloseEditModal = () => {
        // Speichere die aktuelle Modal-Größe in den Cookies
        const modalContent = document.querySelector('.modal-content');
        if (modalContent) {
            const size = {
                width: modalContent.offsetWidth,
                height: modalContent.offsetHeight
            };
            console.log('Speichere Modal-Größe:', size); // Debug-Ausgabe
            Cookies.set('modalSize', JSON.stringify(size), { expires: 365 });
        }

        setIsEditModalOpen(false);
        setEntryToEdit(null);
        fetchEntries();
    };

    const downloadImage = (entryId, imageId) => {
        window.open(`https://api.divingmaster.net/api/entries/${entryId}/images/${imageId}`);
    };

    const downloadAllImages = (entryId) => {
        window.open(`https://api.divingmaster.net/api/entries/${entryId}/images/download`);
    };

    const handleEntryClick = (index) => {
        setEntries(prevEntries => prevEntries.map((entry, idx) => {
            if (idx !== index) return entry;
            return { ...entry, isExpanded: !entry.isExpanded };
        }));
    };

    const handleTextSizeChange = (event, newValue) => {
        setTextSize(newValue);
        Cookies.set('textSize', newValue, { expires: 365 });
    };

    const sortEntries = (entries, order) => {
        return entries.sort((a, b) => {
            // Entfernt Zahlen aus den Namen
            const nameA = a.name.replace(/[0-9]/g, '');
            const nameB = b.name.replace(/[0-9]/g, '');

            if (order === 'asc') {
                return nameA.localeCompare(nameB, undefined, { ignorePunctuation: true });
            } else {
                return nameB.localeCompare(nameA, undefined, { ignorePunctuation: true });
            }
        });
    };

    const handleSortOrderChange = () => {
        setSortOrder(prevOrder => (prevOrder === 'asc' ? 'desc' : 'asc'));
    };

    // Wird beim Bewegen der Maus während des Resize-Vorgangs aufgerufen
    const handleMouseMove = (e) => {
        if (!isResizing) return;
        // Stelle sicher, dass wir uns auf das 'modal-content'-Element beziehen
        if (!modalContentRef.current) return;

        const rect = modalContentRef.current.getBoundingClientRect();
        setModalSize({
            width: e.clientX - rect.left,
            height: e.clientY - rect.top
        });
    };

    // Wird aufgerufen, sobald der Resize-Vorgang beendet wird
    const stopResize = () => {
        setIsResizing(false);
        // Speichere die aktuelle Größe in den Cookies
        Cookies.set('modalSize', JSON.stringify(modalSize), { expires: 365 });
    };

    // Startet den Resize-Vorgang
    const startResize = () => setIsResizing(true);

    return (
        <div className="entry-management" style={{ fontSize: `${textSize}px` }}>
            <h1 style={{ fontSize: '16' }}>Einträge verwalten</h1>

            {/* Container für Slider, Filter-Button und Sortier-Button */}
            <div className="controls-container">
                <div className="text-size-controls">
                    <span>Textgrösse:</span>
                    <Slider
                        value={textSize}
                        onChange={handleTextSizeChange}
                        min={5}
                        max={30}
                        step={1}
                        valueLabelDisplay="auto"
                        color="primary"
                    />
                </div>

                <button onClick={handleOpenFilterModal} >
                    <FontAwesomeIcon icon={faFilter} /> Filter
                </button>

                <button onClick={handleSortOrderChange} style={{ marginLeft: '10px' }}>
                    {sortOrder === 'asc' ? 'Sortiere Z-A' : 'Sortiere A-Z'}
                </button>
            </div>

            {/* Filter Modal */}
            {showFilterModal && (
                <div 
                    className="modal"
                    onMouseMove={handleFilterModalMouseMove}
                    onMouseUp={stopFilterResize}
                    onMouseLeave={stopFilterResize}
                >
                    <div 
                        ref={filterModalRef}
                        className="modal-content"
                        style={{
                            width: `${filterModalSize.width}px`,
                            height: `${filterModalSize.height}px`
                        }}
                    >
                        <h2>Filter anwenden</h2>
                        <div className="resize-handle" onMouseDown={startFilterResize} />
                        
                        {/* Eingabefeld zum Suchen nach Namen */}
                        <div className="section">
                            <label htmlFor="nameFilter">Name:</label>
                            <input
                                type="text"
                                id="nameFilter"
                                value={nameFilter}
                                onChange={(e) => setNameFilter(e.target.value)}
                            />
                        </div>
                        
                        {/* DynamicCheckboxes für Filter */}
                        <DynamicCheckboxes
                            kategorien={filterModalKategorien}
                            setKategorien={handleFilterKategorienChange}
                            isFilter={true}
                        />
                        
                        {/* Button-Gruppe */}
                        <div className="button-group">
                            <button onClick={handleApplyFilters}>Anwenden</button>
                            <button onClick={handleCloseFilterModal}>Schließen</button>
                        </div>
                    </div>
                </div>
            )}

            {/* Einträge Liste */}
            <div className="entries-list">
                {sortEntries(entries, sortOrder).map((entry, index) => {
                    return (
                        <div key={entry._id} className="entry-item">
                            <h2 onClick={() => handleEntryClick(index)}>
                                {entry.isExpanded ? '[-]' : '[+]'} {entry.name}
                            </h2>
                            {entry.isExpanded && (
                                <div className="entry-details">
                                    <div className="entry-actions">
                                        <button onClick={() => handleDeleteEntry(entry._id)}>
                                            <FontAwesomeIcon icon={faTrash} /> Löschen
                                        </button>
                                        <button onClick={() => handleEditEntry(entry)}>
                                            <FontAwesomeIcon icon={faEdit} /> Bearbeiten
                                        </button>
                                        <button onClick={() => downloadAllImages(entry._id)}>
                                            <FontAwesomeIcon icon={faDownload} /> Alle Bilder herunterladen
                                        </button>
                                    </div>

                                    {/* Notizen des Eintrags anzeigen */}
                                    <div className="entry-notes-box">
                                        <h3>Notizen:</h3>
                                        {entry.notes && entry.notes.trim() !== '' ? (
                                            <p>{entry.notes}</p>
                                        ) : (
                                            <p>Keine Notizen vorhanden.</p>
                                        )}
                                    </div>

                                    {/* Bilder des Eintrags anzeigen */}
                                    <div className="entry-images">
                                        {entry.images.map(image => (
                                            <div key={image._id} className="image-item">
                                                <img
                                                    src={`https://api.divingmaster.net/api/entries/${entry._id}/images/${image._id}`}
                                                    alt={image.name}
                                                    onClick={() => window.open(`https://api.divingmaster.net/api/entries/${entry._id}/images/${image._id}`)}
                                                />
                                                {/* Button durch Icon ersetzen */}
                                                <button onClick={() => window.open(`https://api.divingmaster.net/api/entries/${entry._id}/images/${image._id}`)}>
                                                    <FontAwesomeIcon icon={faExpand} />
                                                </button>
                                            </div>
                                        ))}
                                    </div>
                                </div>
                            )}
                        </div>
                    );
                })}
            </div>

            {/* Edit Modal */}
            {isEditModalOpen && (
                <div
                    className="modal"
                    onMouseMove={handleMouseMove}
                    onMouseUp={stopResize}
                    onMouseLeave={stopResize}
                >
                    <div
                        ref={modalContentRef}
                        className="modal-content"
                        style={{
                            width: `${modalSize.width}px`,
                            height: `${modalSize.height}px`
                        }}
                    >
                        <button
                            className="close-button"
                            onClick={handleCloseEditModal}
                        >
                            X
                        </button>
                        <div
                            className="resize-handle"
                            onMouseDown={startResize}
                        />
                        <EditEntryForm
                            entry={entryToEdit}
                            onClose={handleCloseEditModal}
                        />
                    </div>
                </div>
            )}

            {/* Paginierung */}
            <div className="pagination">
                <button onClick={() => setPage(prev => Math.max(prev - 1, 1))}>
                    <FontAwesomeIcon icon={faArrowLeft} /> Vorherige
                </button>
                <span>Seite {page} von {totalPages}</span>
                <button onClick={() => setPage(prev => Math.min(prev + 1, totalPages))}>
                    Nächste <FontAwesomeIcon icon={faArrowRight} />
                </button>
                <label>
                    Einträge pro Seite:
                    <input
                        type="number"
                        value={limit}
                        onChange={(e) => setLimit(parseInt(e.target.value))}
                    />
                </label>
            </div>

            {/* Snackbar-Komponente */}
            <Snackbar
                open={openSnackbar}
                autoHideDuration={6000}
                onClose={handleCloseSnackbar}
                anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
            >
                <Alert
                    onClose={handleCloseSnackbar}
                    severity={snackbarSeverity}
                    sx={{ width: '100%' }}
                >
                    {snackbarMessage}
                </Alert>
            </Snackbar>
        </div>
    );
}

export default EntryManagementPage;