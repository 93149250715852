// src/components/SubmitButton.js
import React, { useState } from 'react';
import axios from 'axios';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSave } from '@fortawesome/free-solid-svg-icons'; // Icon importieren
import { Snackbar, Alert } from '@mui/material'; // Neu hinzugefügt

function SubmitButton({ data }) {
    const [openSnackbar, setOpenSnackbar] = useState(false); // Snackbar-Status
    const [snackbarMessage, setSnackbarMessage] = useState('');
    const [snackbarSeverity, setSnackbarSeverity] = useState('success');

    const handleSubmit = () => {
        const formData = new FormData();

        formData.append('name', data.name);
        formData.append('notes', data.notes);

        data.images.forEach((image) => {
            formData.append('images', image.file);
        });

        // Ermitteln der ausgewählten Optionen mit Kategorien und Unterkategorien
        const selectedOptions = [];
        data.kategorien.forEach((category) => {
            category.unterkategorien.forEach((sub) => {
                sub.optionen.forEach((option) => {
                    if (option.isChecked) {
                        selectedOptions.push({
                            category: category.name,
                            subcategory: sub.name,
                            option: option.name,
                        });
                    }
                });
            });
        });

        formData.append('kategorien', JSON.stringify(selectedOptions));

        axios
            .post('https://api.divingmaster.net/api/entries', formData)
            .then((response) => {
                setSnackbarMessage('Eintrag erfolgreich erstellt.');
                setSnackbarSeverity('success');
                setOpenSnackbar(true);
                // Weiterleitung oder weitere Aktionen
            })
            .catch((error) => {
                console.error('Fehler beim Erstellen des Eintrags:', error);
                setSnackbarMessage('Fehler beim Erstellen des Eintrags.');
                setSnackbarSeverity('error');
                setOpenSnackbar(true);
            });
    };

    // Handler zum Schließen der Snackbar
    const handleCloseSnackbar = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        setOpenSnackbar(false);
    };

    return (
        <>
            <style>
                {`
                    .button-container {
                        margin-top: 20px;
                    }

                    .save-button {
                        background-color: #007bff;
                        padding: 10px 20px;
                        align-items: center;
                        font-size: 16px;
                        border: none;
                        border-radius: 4px;
                        cursor: pointer;
                        transition: background-color 0.3s ease;
                    }

                    .save-button:hover {
                        background-color: #0056b3;
                    }
                `}
            </style>
            <div className="button-container">
                <button className="save-button" onClick={handleSubmit}>
                    <FontAwesomeIcon icon={faSave} /> Speichern
                </button>
            </div>

            {/* Snackbar-Komponente */}
            <Snackbar
                open={openSnackbar}
                autoHideDuration={6000}
                onClose={handleCloseSnackbar}
                anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
            >
                <Alert
                    onClose={handleCloseSnackbar}
                    severity={snackbarSeverity}
                    sx={{ width: '100%' }}
                >
                    {snackbarMessage}
                </Alert>
            </Snackbar>
        </>
    );
}

export default SubmitButton;