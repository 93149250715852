// src/components/EntryForm.js
import React, { useState } from 'react';
import TextFields from './TextFields';
import ImageUploader from './ImageUploader';
import DynamicCheckboxes from './DynamicCheckboxes';
import SubmitButton from './SubmitButton';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrash } from '@fortawesome/free-solid-svg-icons';

function EntryForm() {
    const [name, setName] = useState('');
    const [notes, setNotes] = useState('');
    const [images, setImages] = useState([]);
    const [kategorien, setKategorien] = useState([]);

    const handleClear = () => {
        setName('');
        setNotes('');
        setImages([]);
        setKategorien([]);
    };

    const data = {
        name,
        notes,
        images,
        kategorien,
    };

    return (
        <>
            <style>
                {`
                    .entry-form-container {
                        display: flex;
                        height: 100vh;
                        box-sizing: border-box;
                    }

                    .form-section {
                        flex: 1;
                        padding: 20px;
                        overflow-y: auto;
                        box-sizing: border-box;
                    }

                    .form-section:first-child {
                        border-right: 1px solid #ccc;
                    }

                    .form-section h1 {
                        margin-bottom: 20px;
                    }

                    .form-field {
                        margin-bottom: 15px;
                    }

                    .button-container {
                        position: fixed;
                        bottom: 20px;
                        right: 20px;
                        display: flex;
                        flex-direction: column;
                        gap: 10px;
                    }

                    .clear-button, .save-button {
                        padding: 10px 20px;
                        align-items: center;
                        font-size: 16px;
                        border: none;
                        border-radius: 4px;
                        cursor: pointer;
                        transition: background-color 0.3s ease;
                        color: white;
                    }

                    .clear-button {
                        background-color: #dc3545;
                    }

                    .clear-button:hover {
                        background-color: #c82333;
                    }
                `}
            </style>
            <div className="entry-form-container">
                <div className="form-section">
                    <h1>Eintragsformular</h1>
                    <div className="form-field">
                        <TextFields
                            name={name}
                            setName={setName}
                            notes={notes}
                            setNotes={setNotes}
                        />
                    </div>
                    <div className="form-field">
                        <ImageUploader images={images} setImages={setImages} />
                    </div>
                </div>
                <div className="form-section">
                    <div className="form-field">
                        <DynamicCheckboxes
                            kategorien={kategorien}
                            setKategorien={setKategorien}
                        />
                    </div>
                </div>
                <div className="button-container">
                    <SubmitButton data={data} />
                    <button 
                        onClick={handleClear}
                        className="clear-button"
                        type="button"
                    >
                        <FontAwesomeIcon icon={faTrash} /> Zurücksetzen
                    </button>
                </div>
            </div>
        </>
    );
}

export default EntryForm;